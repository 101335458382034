<template>
    <div class="chat-button" @click="openChat">
      <i class="fas fa-comments"></i>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ChatButton',
    methods: {
      openChat() {
        // Implement chat functionality here
        alert('Chat functionality coming soon!')
      }
    }
  }
  </script>
  