export class FilterProcessor {
  constructor() {
    this.rules = new Map()
  }

  processEmail(email) {
  const matches = []
  for (const [id, rule] of this.rules) {
    if (this.matchesRule(email, rule)) {
      matches.push({ id, ...rule })
    }
  }
  return matches
}


  matchesRule(email, rule) {
    return rule.conditions.every(condition => {
      switch (condition.type) {
        case 'subject':
          return email.subject.includes(condition.value)
        case 'sender':
          return email.sender.includes(condition.value)
        case 'content':
          return email.content.includes(condition.value)
        default:
          return false
      }
    })
  }

  executeAction(email, rule) {
    switch (rule.action) {
      case 'move_to_folder':
        return this.moveToFolder(email, rule.folder)
      case 'mark_as_read':
        return this.markAsRead(email)
      case 'delete':
        return this.deleteEmail(email)
      case 'forward':
        return this.forwardEmail(email, rule.forwardTo)
    }
  }
}
