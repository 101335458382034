export class NotificationHandler {
  constructor(websocket) {
    this.websocket = websocket
    this.subscribers = new Set()
  }

  subscribe(callback) {
    this.subscribers.add(callback)
    return () => this.subscribers.delete(callback)
  }

  notify(notification) {
    this.subscribers.forEach(callback => callback(notification))
    this.websocket.send(JSON.stringify(notification))
  }

  handleIncoming(message) {
    const notification = JSON.parse(message)
    this.subscribers.forEach(callback => callback(notification))
  }
}
