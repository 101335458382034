import { initializeApp } from 'firebase/app'
import { getAuth, updateProfile } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyBViRG3vwWFAUSeOCxteass-DYW7WwWC5I',
  authDomain: 'hardrivehostingit-auth.firebaseapp.com',
  projectId: 'hardrivehostingit-auth',
  storageBucket: 'hardrivehostingit-auth.appspot.com',
  messagingSenderId: '819955031527',
  appId: '1:819955031527:web:71f22a840c18780de07142',
  measurementId: 'G-3JBF5496T9',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
auth.onAuthStateChanged(async user => {
  if (user) {
    const username = user.email.split('@')[0]
    await updateProfile(user, {
      displayName: username,
    })
  }
})

export { auth }
