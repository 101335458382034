import { createStore } from 'vuex'
import { auth } from '@/firebase'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth'
export default createStore({
  state: {
    user: null,
    isLoggedIn: false,
    currentPlan: null,
    darkMode: false,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
      state.isLoggedIn = !!user
    },
    SET_CURRENT_PLAN(state, plan) {
      state.currentPlan = plan
    },
    TOGGLE_DARK_MODE(state) {
      state.darkMode = !state.darkMode
    },
    setDomains(state, domains) {
      state.domains = domains ||[]
    }
  },
  
  actions: {
    async register({ commit }, { email, password }) {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )
      commit('SET_USER', userCredential.user)
    },
    async login({ commit }, { email, password }) {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )
      commit('SET_USER', userCredential.user)
    },
    async logout({ commit }) {
      await signOut(auth)
      commit('SET_USER', null)
    },
    async fetchDomains({ commit }) {
      const response = await fetch('https://hardrivehosting-api.hardiskytrev.workers.dev/api/domains')
      const domains = await response.json()
      commit('setDomains', domains)
    },
    selectPlan({ commit }, plan) {
      commit('SET_CURRENT_PLAN', plan)
    },
    toggleDarkMode({ commit }) {
      commit('TOGGLE_DARK_MODE')
    },
  },
  getters: {
    isLoggedIn: state => state.isLoggedIn,
    currentUser: state => state.user,
    currentPlan: state => state.currentPlan,
    isDarkMode: state => state.darkMode,
  },
})
