<template>
  <div id="app">
    <Navbar :isLoggedIn="isLoggedIn" />
    <router-view></router-view>
    <ChatButton />
    <DarkModeToggle />
  </div>
</template>

<script>
import Navbar from "./components/AppNavbar.vue";
import ChatButton from "./components/ChatButton.vue";
import DarkModeToggle from "./components/DarkModeToggle.vue";

export default {
  name: "App",
  components: {
    Navbar,
    ChatButton,
    DarkModeToggle,
  },
  data() {
    return {
      isLoggedIn: false,
    };
  },
  methods: {
    handleLogin() {},
  },
  mounted() {
    this.isLoading = false;
  },
};
</script>

<style>
[v-cloak] {
  display: none;
}
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 60px; /* Adjust this value based on your navbar height */
}

.router-view-container {
  flex: 1;
  padding-top: 60px; /* Adjust based on your navbar height */
}

/* Ensure modals appear on top */
.modal {
  z-index: 1000;
}

/* Position fixed elements */
.chat-button,
.dark-mode-toggle {
  position: fixed;
  z-index: 100;
}

.chat-button {
  bottom: 20px;
  right: 20px;
}

.dark-mode-toggle {
  top: 20px;
  right: 20px;
}
</style>
