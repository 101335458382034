import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import '@/assets/global.css'
import { FilterProcessor } from './services/filterProcessor'
import { NotificationHandler } from './services/notificationHandler'
import { TemplateRenderer } from './services/templateRenderer'
import { WebSocketManager } from './services/websocketManager'
import { serviceProvider } from './services/ServiceProvider'

const app = createApp(app)

app.config.globalProperties.$services = serviceProvider


// Initialize services
const wsManager = new WebSocketManager('wss://api.yourdomain.com/ws')
const notificationHandler = new NotificationHandler(wsManager)
const filterProcessor = new FilterProcessor()
const templateRenderer = new TemplateRenderer()

// Register WebSocket handlers
wsManager.registerHandler('new_email', (message) => {
  const matches = filterProcessor.processEmail(message.email)
  matches.forEach(rule => {
    notificationHandler.notify({
      type: 'filter_match',
      rule,
      email: message.email
    })
  })
})

wsManager.registerHandler('notification', (message) => {
  const rendered = templateRenderer.render(message.templateId, message.data)
  notificationHandler.notify({
    type: 'template_notification',
    ...rendered
  })
})

// Connect WebSocket
wsManager.connect()

// Make services available globally
app.config.globalProperties.$services = {
  notifications: notificationHandler,
  filters: filterProcessor,
  templates: templateRenderer,
  websocket: wsManager
}

createApp(App).use(router).use(store).use(Toast).mount('#app')
