<template>
  <button class="dark-mode-toggle" @click="toggleDarkMode">
    <i :class="isDarkMode ? 'fas fa-sun' : 'fas fa-moon'"></i>
  </button>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'DarkModeToggle',
  setup() {
    const isDarkMode = ref(false)

    const toggleDarkMode = () => {
      isDarkMode.value = !isDarkMode.value
      document.body.classList.toggle('dark-mode', isDarkMode.value)
      localStorage.setItem('darkMode', isDarkMode.value)
    }

    onMounted(() => {
      const savedMode = localStorage.getItem('darkMode')
      if (savedMode !== null) {
        isDarkMode.value = JSON.parse(savedMode)
        document.body.classList.toggle('dark-mode', isDarkMode.value)
      }
    })

    return { isDarkMode, toggleDarkMode }
  }
}
</script>

<style scoped>
.dark-mode-toggle {
  background: none;
  border: none;
  color: var(--text-color);
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}
</style>
