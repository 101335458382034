export class TemplateRenderer {
  constructor() {
    this.templates = new Map()
  }

  render(templateId, data) {
    const template = this.templates.get(templateId)
    if (!template) throw new Error('Template not found')

    let rendered = template.body
    Object.entries(data).forEach(([key, value]) => {
      rendered = rendered.replace(new RegExp(`{${key}}`, 'g'), value)
    })

    return {
      subject: this.renderString(template.subject, data),
      body: rendered
    }
  }

  renderString(template, data) {
    let rendered = template
    Object.entries(data).forEach(([key, value]) => {
      rendered = rendered.replace(new RegExp(`{${key}}`, 'g'), value)
    })
    return rendered
  }
}
