<template>
  <nav class="fixed top-0 left-0 right-0 z-50 bg-opacity-90 bg-black">
    <div class="container mx-auto px-4 py-4 flex justify-between items-center">
      <router-link :to="{ name: 'Home' }" class="logo"
        >Hardrivehosting.it</router-link
      >
      <div class="hidden md:flex">
        <router-link :to="{ name: 'Home' }" class="nav-link">Home</router-link>
        <router-link :to="{ name: 'Features' }" class="nav-link"
          >Features</router-link
        >
        <router-link :to="{ name: 'Pricing' }" class="nav-link"
          >Pricing</router-link
        >
        <router-link :to="{ name: 'Testimonials' }" class="nav-link"
          >Testimonials</router-link
        >
        <router-link :to="{ name: 'Contact' }" class="nav-link"
          >Contact</router-link
        >
      </div>
      <div>
        <template v-if="!currentUser">
          <router-link to="/login" class="btn-primary">Login</router-link>
          <router-link to="/register" class="btn-primary">Register</router-link>
        </template>
        <template v-else>
          <router-link to="/dashboard" class="btn-primary"
            >Dashboard</router-link
          >
          <button @click="handleLogout" class="btn-primary">Logout</button>
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, onMounted } from 'vue'
import { auth } from '../firebase'
import { signOut } from 'firebase/auth'
import { useRouter } from 'vue-router'
export default {
  name: 'AppNavBar',
  setup() {
    const currentUser = ref(null)
    const router = useRouter()

    onMounted(() => {
      auth.onAuthStateChanged(user => {
        currentUser.value = user
      })
    })

    const handleLogout = async () => {
      await signOut(auth)
      router.push('/login')
    }

    return {
      currentUser,
      handleLogout,
    }
  },
}
</script>
