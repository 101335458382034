export class WebSocketManager {
  constructor() {
    this.url = 'wss://hardrivehosting-websocket.hardiskytrev.workers.dev'
    this.handlers = new Map()
    this.reconnectAttempts = 0
    this.maxReconnectAttempts = 5
    this.messageQueue = []
    this.isConnecting = false
    this.connect()
  }

  connect() {
    if (this.isConnecting) return
    this.isConnecting = true
    
    this.socket = new WebSocket(this.url)
    
    this.socket.onopen = () => {
      this.isConnecting = false
      this.reconnectAttempts = 0
      this.startHeartbeat()
      this.send('auth', { token: localStorage.getItem('authToken') })
      this.processMessageQueue()
    }

    this.socket.onmessage = (event) => {
      const message = JSON.parse(event.data)
      const handler = this.handlers.get(message.type)
      if (handler) handler(message)
    }

    this.socket.onclose = () => {
      this.isConnecting = false
      this.stopHeartbeat()
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        setTimeout(() => {
          this.reconnectAttempts++
          this.connect()
        }, 1000 * Math.pow(2, this.reconnectAttempts))
      }
    }
  }

  startHeartbeat() {
    this.heartbeatInterval = setInterval(() => {
      this.send('ping', { timestamp: Date.now() })
    }, 30000)
  }

  stopHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval)
      this.heartbeatInterval = null
    }
  }

  registerHandler(type, callback) {
    this.handlers.set(type, callback)
  }

  send(type, data) {
    const message = JSON.stringify({ type, data })
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.send(message)
    } else {
      this.messageQueue.push(message)
    }
  }

  processMessageQueue() {
    while (this.messageQueue.length > 0) {
      const message = this.messageQueue.shift()
      this.socket.send(message)
    }
  }
}
