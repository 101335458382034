import { ref, reactive } from 'vue'
import { FilterProcessor } from './filterProcessor'
import { NotificationHandler } from './notificationHandler'
import { TemplateRenderer } from './templateRenderer'
import { WebSocketManager } from './websocketManager'


export class ServiceProvider {
  constructor() {
    this.state = reactive({
      connected: false,
      notifications: [],
      filters: new Map(),
      templates: new Map()
    })
    
    this.filterProcessor = new FilterProcessor()
    this.notificationHandler = new NotificationHandler()
    this.templateRenderer = new TemplateRenderer()
    this.wsManager = new WebSocketManager()
    
    this.initialize()
  }

  initialize() {
    // Initialize WebSocket connection
    this.wsManager.connect()
    
    // Set up notification handling
    this.notificationHandler.subscribe(notification => {
      this.state.notifications.push(notification)
    })
    
    // Register WebSocket handlers
    this.wsManager.registerHandler('email', this.handleNewEmail.bind(this))
    this.wsManager.registerHandler('notification', this.handleNotification.bind(this))
  }

  handleNewEmail(email) {
    const matches = this.filterProcessor.processEmail(email)
    matches.forEach(rule => {
      this.notificationHandler.notify({
        type: 'filter_match',
        rule,
        email
      })
    })
  }

  handleNotification(data) {
    const rendered = this.templateRenderer.render(data.templateId, data.variables)
    this.notificationHandler.notify(rendered)
  }
}

export const serviceProvider = new ServiceProvider()
