import { createRouter, createWebHistory } from 'vue-router'
import { auth } from '../firebase'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/HomePage.vue')
  },
  {
    path: '/features',
    name: 'Features',
    component: () => import('../views/FeaturesSection.vue')
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import('../views/PricingPlans.vue')
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import('../views/TestimonialsSection.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/ContactPage.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginView.vue'),
    meta: { requiresGuest: true }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/RegisterView.vue'),
    meta: { requiresGuest: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/DashboardView.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/domains',
    name: 'DomainManager',
    component: () => import('../components/DashboardViews/DomainManager.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/files',
    name: 'FileManager',
    component: () => import('../components/DashboardViews/FileManager.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/databases',
    name: 'DatabaseManager',
    component: () => import('../components/DashboardViews/DatabaseManager.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/email',
    name: 'EmailManager',
    component: () => import('../components/DashboardViews/EmailManager.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/statistics',
    name: 'Statistics',
    component: () => import('../components/DashboardViews/StatisticsManager.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/testing',
    name: 'TestingManager',
    component: () => import('../components/DashboardViews/TestingManager.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/security',
    name: 'Security',
    component: () => import('../components/DashboardViews/SecurityManager.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/databases/phpmyadmin',
    name: 'phpmyadmin',
    component: () => import('../components/DashboardViews/PHPAdmin.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/dashboard/webmail',
    name: 'webmail',
    component: () => import('../components/DashboardViews/Webmail.vue'),
    meta: { requiresAuth: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresGuest = to.matched.some(record => record.meta.requiresGuest)

  if (requiresAuth && !currentUser) {
    next('/login')
  } else if (requiresGuest && currentUser) {
    next('/dashboard')
  } else {
    next()
  }
})

export default router
